@import "@sundae/gov-toolkit/dist/styles.scss";
@tailwind base;
@tailwind components;
@tailwind utilities;
@tailwind variants;

@layer components {
    header nav .text-primary {
        @apply text-black;
    }
}